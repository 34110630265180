





























































































































































import { Vue, Component, InjectReactive, Watch } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { SelectionChangedEvent, GridReadyEvent } from '@ag-grid-community/core';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ClientStatusEnum } from '@/enums/crm/client-status.enum';
import RouterService from '@/services/router.service';
import ContentDialog from '@/components/content-dialog.vue';
import { ArrayHelper } from '@/utils/helpers/array-helper';
import ClientModel from '@/models/crm/client.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import EmailModel from '@/models/crm/email.model';
import EmailService from '@/services/crm/email.service';
import { DateHelper } from '@/utils/helpers/date-helper';
import { StringHelper } from '@/utils/helpers/string-helper';
import { EmailOriginEnum } from '@/enums/crm/email-origin.enum';
import CrmEmailView from '@/components/crm/email-view.vue';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ActivityService from '@/services/crm/activity.service';
import GroupModel from '@/models/crm/group.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import dayjs from '@/plugins/dayjs';

type DataGridFilterConfig = {
  keyword: string | undefined;
  origin: string | undefined;
  groupArea: number[] | undefined;
  historyType: number[] | undefined;
};

@Component({
  components: {
    DataGridFilter,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
    CrmEmailView,
  },
})
export default class CrmEmails extends Vue {
  @inject(InjectionIdEnum.CrmEmailService)
  private emailService!: EmailService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('clientType') readonly clientType!: ClientTypeEnum;

  @InjectReactive('userContactInfo') readonly userContactInfo!: UserContactInfo;

  grid: GridReadyEvent | null = null;

  modalBody = {
    dataHora: 'Data e Hora',
    destinatario: 'Destinatário',
    origem: 'Origem',
    atendente: 'Atendente',
    descricao: 'Descrição',
    titulo: 'Título',
    anexo: 'Anexo',
  };

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'inclusaoData', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.emails.grid.origin')}`,
        field: 'origem',
        valueGetter: (params): string => GridHelper.valueGetter(params, StringHelper.toTitleCase),
        maxWidth: 75,
      },
      {
        headerName: `${this.$t('crm.view.emails.grid.date')}`,
        field: 'inclusaoData',
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        maxWidth: 145,
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.emails.grid.subject')}`,
        field: 'assunto',
        flex: 0.75,
      },
      {
        headerName: `${this.$t('crm.view.emails.grid.attachments')}`,
        colId: 'attachments',
        field: 'anexos',
        cellRenderer: (param): string => {
          let ret = '';
          if (param != null && param.data != null && param.data.anexos != null
            && param.data.anexos !== '') {
            ret += '<a id="open">Visualizar</a><br><br><a id="download">Download</a>';
          }
          return ret;
        },
        onCellClicked: (event): void => {
          if (event.data != null && event.data.anexos != null) {
            JSON.parse(event.data.anexos).forEach((anexo) => {
              if (event.event?.target != null
                && (<HTMLTextAreaElement> event.event?.target).id === 'open') {
                const w = window.open('');
                w?.document.write(`<iframe allowfullscreen webkitallowfullscreen mozallowfullscreen
                width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0"
                scrolling="no" src="${anexo.base64}"></iframe>`);
              } else if (event.event?.target != null
                && (<HTMLTextAreaElement> event.event?.target).id === 'download') {
                const element = document.createElement('a');
                element.setAttribute('href', anexo.base64);
                element.setAttribute('download', anexo.nome);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
              }
            });
          }
        },
        flex: 0.5,
        autoHeight: true,
        sortable: false,
      },
      {
        headerName: `${this.$t('crm.view.emails.grid.attendant')}`,
        field: 'nomeAtendente',
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.emails.grid.recipients')}`,
        field: 'destinatarios',
        valueGetter: (params): string[] => [...(params.data.destinatarios || []), ...(params.data.copias || [])],
        flex: 0.7,
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.view.emails.grid.groupArea')}`,
        field: 'grupoArea.descricao',
        flex: 0.7,
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.view.emails.grid.historyType')}`,
        field: 'tipoHistorico.descricao',
        flex: 0.7,
        autoHeight: true,
      },
    ],
  };

  filters: DataGridFilterConfig = {
    keyword: undefined,
    origin: undefined,
    groupArea: undefined,
    historyType: undefined,
  };

  filterOriginOptions = [
    {
      code: EmailOriginEnum.Contact,
      name: `${this.$t('crm.view.emails.origin.contact')}`,
    },
    {
      code: EmailOriginEnum.Order,
      name: `${this.$t('crm.view.emails.origin.order')}`,
    },
    {
      code: EmailOriginEnum.Calendar,
      name: `${this.$t('crm.view.emails.origin.calendar')}`,
    },
    {
      code: EmailOriginEnum.Titulo,
      name: `${this.$t('crm.view.emails.origin.titulo')}`,
    },
  ];

  groupAreaOptions: GroupModel[] = [];

  historyTypeOptions: HistoryTypeModel[] = [];

  dialogConfig: IKeyValue<IDialogConfig> = {
    sendEmail: {
      show: false,
    },

    details: {
      show: false,
    },
  };

  itemEmail = new EmailModel();

  items: EmailModel[] = [];

  selected: EmailModel[] = [];

  filteredItems: EmailModel[] = [];

  async mounted(): Promise<void> {
    this.loadFilterOptions();
    this.loadItems();

    const loader = this.$loading.show();

    loader.hide();
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: EmailModel[]): void {
    this.selected = selected;
  }

  onSendEmail(): void {
    this.dialogConfig.sendEmail.show = true;
  }

  openDetEmailDialog(data: EmailModel): void {
    this.dialogConfig.details.show = true;
    this.itemEmail = new EmailModel();
    this.itemEmail = data;
  }

  onAfterSendEmail(): void {
    this.loadItems();
  }

  onExport(selected: EmailModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.filteredItems.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Emails',
        fileName: EmailService.generateEmailExportFilename(new Date()),
      });
    }
  }

  get viewTitle(): string {
    return this.$t('crm.view.emails.title', { clientType: this.$t(`crm.${this.clientType}`) }).toString();
  }

  get detEmailDialogTitle(): string {
    return this.$t('crm.view.emails.details').toString();
  }

  get dataHoraEmail(): string {
    const dataHora = dayjs(this.itemEmail.inclusaoData).format('DD/MM/YYYY HH:mm:ss');
    return dataHora;
  }

  @Watch('filters', { immediate: true, deep: true })
  onFilterChange(value: DataGridFilterConfig): void {
    let filteredItems = this.items;

    if (value.keyword) {
      const columnsToSearch = ['assunto', 'mensagem'];
      filteredItems = ArrayHelper.filterByKeyword(filteredItems, columnsToSearch, value.keyword);
    }

    if (value.origin) {
      filteredItems = filteredItems.filter((item) => item.origem === value.origin);
    }

    if (value.groupArea && value.groupArea.length > 0) {
      filteredItems = filteredItems.filter((item) => item.grupoArea
        && value.groupArea?.indexOf(item.grupoArea.id) !== -1);
    }

    if (value.historyType && value.historyType.length > 0) {
      filteredItems = filteredItems.filter((item) => item.tipoHistorico
        && value.historyType?.indexOf(item.tipoHistorico.id) !== -1);
    }

    this.filteredItems = filteredItems;
  }

  get activeFilters(): number {
    let active = 0;
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      switch (key) {
        default:
          if (this.filters[key]) {
            active += 1;
          }
      }
    });

    return active;
  }

  get isConvertedProspect(): boolean {
    return this.activeClient.situacao === ClientStatusEnum.Converted;
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;
    this.items = await this.emailService.getEmails(this.getClientIdFromRoute(), this.clientType);
    this.filteredItems = this.items;
    this.gridSettings.loading = false;
  }

  private async loadFilterOptions(): Promise<void> {
    this.groupAreaOptions = await this.getGroupAreas();
    this.historyTypeOptions = await this.getHistoryTypes();
  }

  private async getGroupAreas(): Promise<GroupModel[]> {
    return this.activityService.getGroups();
  }

  private async getHistoryTypes(): Promise<HistoryTypeModel[]> {
    return this.activityService.getHistoryTypes();
  }

  private getClientIdFromRoute(): string {
    if (this.clientType === ClientTypeEnum.Prospect) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }

  async onCellClick(event: IGridCellEvent<EmailModel>): Promise<void> {
    this.openDetEmailDialog(event.data);
  }
}
